import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col, Card } from "react-bootstrap";
import "./ButtonCardPage.css"; // Import CSS for custom styles
import African from "./images/africian.png"; // Import your image
import Ganga from "./images/dsd.png";
import kangaroo from "./images/australia_animal.png";
import Temple from "./images/photo.jpg";
import GangaRiver from "./images/gangariver.jpg";
import Sabarmati from "./images/sabarmati.jpg";
import Sabarmati1 from "./images/The-Saint-of-Sabarmati-India.png";

import Akshardham from "./images/akshardham.jpg";
import Buddhism from "./images/buddhism.webp";
import Japan from "./images/japan-the-world.png";
import Ancient from "./images/8-gl-03.jpg";
import Holyland from "./images/holyland.png";
import Environment from "./images/environment.jpg";
import Slik from "./images/slikroad.jpg";
import AustralianSchool from "./images/australian-school.png";
import IndianSchool from "./images/indian-school.png";
import JapanSchool from "./images/japan-school.png";
import IsraeliSchool from "./images/esraeli-school.png";
import KenyanSchool from "./images/kenyan-schools.png";
import { useNavigate } from "react-router-dom";

const ButtonCardPage = () => {
  // Updated data array with nested cardData for "For Australian Schools" and "For Indian Schools"
  const data = [
    {
      id: 1,
      title: "For Australian Schools",
      text: "Engage with classrooms across the world in real-time.",
      cards: [
        {
          id: 1,
          title: "Year 4 Geography",
          subtitle: "Year 4 Geography The Earth’s Climate",
          description: "African Safari (Kenya)",
          url:"African Safari (Kenya)",
          span1: "AU",
          h1: "$15",
          span2: "per student,",
          span3: "subject to minimum $650 (plus GST)",
          image: African,
        },
        {
          id: 2,
          title: "Year 7 Geography",
          subtitle: "Year 7 Geography Water in the World",
          description: "Ganga River Ecosystem, India",
          url:"Year 7 Geography Water in the World",
          span1: "AU",
          h1: "$15",
          span2: "per student,",
          span3: "subject to minimum $650 (plus GST)",
          image: Ganga,
        },
        
          {
            id: 3,
            title: "Year 10 History Rights & Freedoms",
            subtitle: "Year 10 History Rights & Freedoms",
            description: "Indian Independence Movement: The Saint of Sabarmati",
            url:"rights-freedoms",
            span1: "AU",
            h1: "$15",
            span2: "Per student,",
            span3: "subject to minimum $750 (plus GST)",
            image: Sabarmati1,
          },
      
        {
          id: 4,
          title: "Year 9 Geography",
          subtitle: "Year 9 Geography Geographies of Interconnections",
          description: "The Silk Roads of Samarkand, Uzbekistan",
          span1: "AU",
          url:'geographies-of-interconnections',
          h1: "$15",
          span2: "per student,",
          span3: "subject to minimum $650 (plus GST)",
          image: Temple,
        },
      ],
    },
    {
      id: 2,
      title: "For Indian Schools",
      text: "Learn about different cultures and enhance your global competence.",
      cards: [
        {
          id: 1,
          subtitle: "Year 7 Geography Water in the World",
          description: "The Ganga River Ecosystem",
          url:'ganga-river-ecosystem',
          span1: "INR",
          h1: "150",
          span2: "per student",
          span3: "",
          image: GangaRiver,
        },
        {
          id: 2,
          subtitle: "Year 10 History Rights & Freedoms",
          description: "The Saint of Sabarmati",
          url:"the-saint-of-sabarmati",
          span1: "INR",
          h1: "150",
          span2: "per student",
          span3: "",
          image: Sabarmati,
        },
        {
          id: 3,
          subtitle: "Year 8 Religious Education",
          description: "Mystical Hinduism",
          url:"hinduism",
          span1: "INR",
          h1: "150",
          span2: "per student",
          span3: "",
          image: Akshardham,
        },
        {
          id: 4,
          subtitle: "Year 7 Religious Education",
          description: "In the Buddha’s Footsteps",
          url:"buddhism",
          span1: "INR",
          h1: "150",
          span2: "per student",
          span3: "",
          image: Buddhism,
        },
      ],
    },
    {
      id: 3,
      title: "For Japanese Schools",
      text: "Practice new languages with native speakers in partner schools.",
      cards: [
        {
          id: 1,
          subtitle: "Year 8 History",
          description: "Shogunate Japan",
          url:"shogunate-japan",
          span1: "JPY",
          h1: "1,900",
          span2: "per student",
          span3: "",
          image: Japan,
        },
      ],
    },
    {
      id: 4,
      title: "For Israeli Schools",
      text: "Practice new languages with native speakers in partner schools.",
      cards: [
        {
          id: 1,
          subtitle: "Year 7 History",
          description: "Investigating the Ancient Past",
          url:'the-holy-land',
          span1: "s",
          h1: "24",
          span2: "per student",
          span3: "",
          image: Ancient,
        },
        {
          id: 2,
          subtitle: "Year 9/10 Religious Education",
          description: "The Holy Land",
          url:'judaism-christianity',
          span1: "S",
          h1: "24",
          span2: "per student",
          span3: "",
          image: Holyland,
        },
      ],
    },
    {
      id: 5,
      title: "For Kenyan Schools",
      text: "Practice new languages with native speakers in partner schools.",
      cards: [
        {
          id: 1,
          subtitle: "Year 10 Geography",
          description: "Environmental Change & Management",
          url:'masai-mara',
          span1: "Ksh",
          h1: "50",
          span2: "per student",
          span3: "",

          image: Environment,
        },
      ],
    },
    {
      id: 6,
      title: "For Uzbek Schools",
      text: "Practice new languages with native speakers in partner schools.",
      cards: [
        {
          id: 1,
          subtitle: "Year 8 Geography Geographies of Interconnections",
          description: "The Silk Roads",
          url:'silk-roads',
          span1: "som",
          h1: "2500",
          span2: "per student",
          span3: "",
          image: Slik,
        },
      ],
    },
  ];

  const SmallData = [
    {
      id: 1,
      title: "For Australian Schools",
      text: "Engage with classrooms across the world in real-time.",
      image: AustralianSchool, // Add image for button card
    },
    {
      id: 2,
      title: "For Indian Schools",
      text: "Learn about different cultures and enhance your global competence.",
      image: IndianSchool,
    },
    {
      id: 3,
      title: "For Japanese Schools",
      text: "Practice new languages with native speakers in partner schools.",
      image: JapanSchool,
    },
    {
      id: 4,
      title: "For Israeli Schools",
      text: "Practice new languages with native speakers in partner schools.",
      image: IsraeliSchool,
    },
    {
      id: 5,
      title: "For Kenyan Schools",
      text: "Practice new languages with native speakers in partner schools.",
      image: KenyanSchool,
    },
    {
      id: 6,
      title: "For Uzbek Schools",
      text: "Practice new languages with native speakers in partner schools.",
      image: Slik,
    },
  ];
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  // Update state based on screen width
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Set the first card (id: 1) as active by default
  const [visibleCard, setVisibleCard] = useState(data[0].id);

  const handleButtonClick = (id) => {
    setVisibleCard(id); // Update the visible card
  };
  const navigate = useNavigate();

  // const handleCardClick = (card) => {
  //   navigate(`/card-detail/${card.id}`, { state: { card } }); // Pass full card object
  // };
  const handleCardClick = (card) => {
    const formattedDescription = card.url.toLowerCase().replace(/\s+/g, "-").replace(/[()]/g, ""); // Convert to lowercase, replace spaces with hyphens, remove special characters
    navigate(`/${formattedDescription}`, { state: { card } });
  };
  
  

  const handleClick = (card) => {
    if (card.id === 1) {
      // Navigate to a specific route for card with id 1
      navigate('/for-australian-school');
    } else {
      // Navigate to the detail page for other cards
      navigate(`/detail/${card.id}`, { state: { card } });
      // const formattedDescription = card.url.toLowerCase().replace(/\s+/g, "-").replace(/[()]/g, ""); // Convert to lowercase, replace spaces with hyphens, remove special characters
      // navigate(`/${formattedDescription}`, { state: { card } });
    }
  };
  
  const handleReadMoreClick = () => {
    navigate('/for-australian-school')
  };


  return (
    <div className="text-center mt-5" style={{ padding: "0px 30px" }}>
      {isSmallScreen ? (
        <Row>
          {SmallData.map((item) => (
            <Col key={item.id} md={3} sm={6} xs={12} className="mb-3">
              <Card
                className="card-button"
                onClick={() => handleClick(item)} // Navigate to detail page on click
                style={{ cursor: "pointer" }}
              >
                <Card.Img
                  variant="top"
                  height={330}
                  src={item.image}
                  alt={item.title}
                />
                <Card.Body>
                  <Card.Title className="card-title">{item.title}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <>
        <Row className="mt-1 school-btn">
          {data.map((item) => (
            <Col key={item.id} md={3} sm={6} xs={12} className="mb-3">
              <Button
                variant="none"
                className={`card-btn ${
                  visibleCard === item.id ? "active-btn" : ""
                }`}
                onClick={() => handleButtonClick(item.id)}
              >
                {item.title}
              </Button>
            </Col>
          ))}
        </Row>
        
      </>
      )}

      {isSmallScreen ? null : (
        <>
        <Row
          className="mt-5"
          style={{
            backgroundColor: "#f5f5f5",
            padding: "10px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {data.map(
            (item) =>
              item.id === visibleCard &&
              item.cards && ( // Check if the clicked button has cards to show
                <React.Fragment key={item.id}>
                  {item.cards.map((card) => (
                    <Col key={card.id} md={3} className="d-flex">
                      <Card
                        className="card-container"
                        onClick={() => handleCardClick(card)} // Add click handler
                        style={{ cursor: "pointer" }} // Add pointer cursor to show it's clickable
                      >
                        <Card.Img
                          variant="top"
                          src={card.image}
                          alt={card.title}
                          style={{ height: "320px", objectFit: "cover" }} // Same image height
                        />
                        <Card.Body>
                          <Card.Title className="card-title">
                            {card.subtitle}
                          </Card.Title>
                          <Card.Text className="card-text">
                            <small>{card.description}</small>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </React.Fragment>
              )
          )}
        </Row>
     {visibleCard === 1 ? 
        <Button type="button"  onClick={handleReadMoreClick} className="read-button mb-5">
        Read More
      </Button>
      :null }
      </>
      )}

{/* {item.id === "FOR AUSTRALIAN SCHOOL" ? 
<Button type="button" onClick={handleReadMoreClick} className="read-button">
        Read More
      </Button>
      :NULL } */}
    </div>
  );
};

export default ButtonCardPage;
