import React, { useState } from "react";
import { Navbar, Nav, Container, Row, Col, Offcanvas } from "react-bootstrap";
import "./Navbar.css"; // Import custom CSS for background
import logo from "./images/logo.png"; // Update this path
import About from "./images/about.png";
import person1 from "./images/about-1.png";
import person2 from "./images/about-2.png";
import person3 from "./images/about-3.png";
import Emailus from "./Emailus";
import "./About.css";

function AboutUs() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);
  return (
    <>
      <div className="contact-image">
        <Navbar expand="lg" variant="dark" className="transparent-navbar">
          <Container>
            <Navbar.Brand href="/">
              <img
                src={logo}
                height="50" // Adjust height based on your requirement
                alt="Vyatra logo"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={handleShow}
            />
            <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex">
              <Nav className="ms-auto nav-links">
                <Nav.Link href="/">HOME</Nav.Link>
                <Nav.Link href="/how-it-works">HOW IT WORKS</Nav.Link>
                <Nav.Link href="/contact">CONTACT</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>  <img
              src={logo}
              height="50" // Adjust height based on your requirement
              alt="Vyatra logo"
              className="d-inline-block align-top"
            /></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-column">
              <Nav.Link href="/" onClick={handleClose}>
                HOME
              </Nav.Link>
              <Nav.Link href="/how-it-works" onClick={handleClose}>
                HOW IT WORKS
              </Nav.Link>
              <Nav.Link href="/contact" onClick={handleClose}>
                CONTACT
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>

        <Container>
          <Row>
            {/* Text Section */}
            <Col md={6} className="contact-text-left">
              <h1>About Us</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Container style={{ marginTop: "100px", marginBottom: "100px" }}>
        <Row>
          <Col>
            <img src={About} alt="about-us" />
          </Col>
          <Col className="about">
            <h2
              style={{
                color: "#2B2C31",

                fontFize: "28px",
                fontFeight: "600",
                textTransform: "none",
                fontStyle: "normal",
                textDecoration: "none",
                lineHeight: "35px",
                marginBottom: "10px",
              }}
            >
              Our Story
            </h2>
            <div style={{ width: "50%" }}>
              <p className="About-p">
                Yatra is a Hindi word for pilgrimage. At vyatra we are creating
                live-streamed, guided virtual journeys to spectacular places.
                Accompanied by live music performances, come and experience one
                of our uniquely immersive journeys with your family and friends.
              </p>
              <p className="About-p">
                We are a team of 3 co-founders: Rakesh (based in Varanasi,
                India), Saurabh & Nitin (both based in Melbourne, Australia).
                Each of us has deeply felt the joy of traveling to and
                connecting with a place and all that it stands for – its sights
                and sounds, its history, its way of life. Altogether, its unique
                way of helping us connect with and express divine bliss! And we
                know full well that this is a sentiment shared by very many.
              </p>
              <p className="About-p">
                However with limited prospects of travel in the short term due
                to the COVID-19 pandemic and then the longer term challenges of
                overcrowding in places with sensitive ecology and high carbon
                footprint associated with physical travel, we conceptualised
                vyatra as a series of LIVE, interactive virtual journeys of
                places that are sacred, spectacular and/or difficult to get to.
              </p>
              <p className="About-p">
                The word “yatra” in Hindi means a journey, and hence v-yatra, a
                virtual journey.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={4}>
            <img src={person1} />
            <h2 style={{ marginTop: "30px" }}>Saurabh Mishra</h2>
            <p className="About-p" style={{ marginTop: "40px" }}>
              vyatra is my sixth business venture since the year 2000, and is a
              wonderful coming together of the things that I value and enjoy. I
              have lived in 3 different countries and travelled extensively
              around the world, so connecting deeply with places is a treasured
              joy. As is music – I am an amateur Hindustani classical vocalist,
              and have co-founded the Melbourne Hindustani Classical Music
              Society (www.mhcms.org). And last but not least, as a long-term
              practitioner of Kriya Yog, journeys that lead us within.
            </p>
          </Col>
          <Col md={4}>
            <img src={person2} />
            <h2 style={{ marginTop: "30px" }}>Nitin Utreja</h2>
            <p className="About-p" style={{ marginTop: "40px" }}>
              I started my entrepreneurial journey with a vision to make a
              difference to other people’s life. Each venture has been a big
              learning curve & always enriched me as a being. My passion to
              travel & instinctive connection with others is the reason of my
              expansion. To conclude, I have been a firm believer of living a
              meditative life is more important than just meditation. All of
              which led me here to vyatra, our recent journey- a new virtual era
              to explore & proliferate.
            </p>
          </Col>
          <Col md={4}>
            <img src={person3} />
            <h2 style={{ marginTop: "30px" }}>Rakesh Roshan</h2>
            <p className="About-p" style={{ marginTop: "40px" }}>
              In my first career as a journalist for a daily newspaper, I was
              fortunate to travel the length and breadth of my country and learn
              and imbibe its rich heritage of Yog practice and wisdom. I then
              worked in commercial & administrative roles for a Cement company
              for 6 years which taught me a lot about business. I presently
              reside in soulful, eternal Varanasi, and so for me vyatra is a
              wonderful way to channel my strengths and interests to present
              India’s rich heritage to the world in an entirely new way.
            </p>
          </Col>
        </Row>
      </Container>
      <Emailus />
    </>
  );
}

export default AboutUs;
