import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, Row, Col, Offcanvas } from "react-bootstrap";
import "./Navbar.css"; // Import custom CSS for background
import logo from "./images/logo.png"; // Update this path
import Emailus from "./Emailus";

const TermsAndCondition = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="contact-image">
        <Navbar expand="lg" variant="dark" className="transparent-navbar">
          <Container>
            <Navbar.Brand href="/">
              <img
                src={logo}
                height="50" // Adjust height based on your requirement
                alt="Vyatra logo"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={handleShow}
            />
            <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex">
              <Nav className="ms-auto nav-links">
                <Nav.Link href="/">HOME</Nav.Link>
                <Nav.Link href="/how-it-works">HOW IT WORKS</Nav.Link>
                <Nav.Link href="/contact">CONTACT</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title> <img
              src={logo}
              height="50" // Adjust height based on your requirement
              alt="Vyatra logo"
              className="d-inline-block align-top"
            /></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-column">
              <Nav.Link href="/" onClick={handleClose}>
                HOME
              </Nav.Link>
              <Nav.Link href="/how-it-works" onClick={handleClose}>
                HOW IT WORKS
              </Nav.Link>
              <Nav.Link href="/contact" onClick={handleClose}>
                CONTACT
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
        <Container>
          <Row>
            {/* Text Section */}
            <Col md={6} className="contact-text-left">
              <h1>Terms & Conditions</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <p className="About-p" style={{ fontWeight: "400", marginTop: "50px" }}>
          Please read the following terms carefully before continuing. This is a
          legal contract between vyatra Pty Ltd. (‘vyatra’ or ‘we’) and you.
        </p>
        <h2 className="terms-h2" style={{ marginTop: "40px" }}>
          Acceptance of Terms of Use
        </h2>
        <p className="About-p" style={{ fontWeight: "400" }}>
          1.1 Your use of our live, virtual guided journeys (“vyatra
          Experiences”) and website (“site”) are conditional upon your agreement
          to, acceptance of and compliance with the terms and conditions set out
          below (including the Privacy Policy) (“terms and conditions”) and the
          terms and conditions are legally binding on you. You must read these
          conditions carefully before purchasing or participating in vyatra
          Experiences and using the site.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          1.2 We reserve the right to amend the terms and conditions at any
          time. You will be deemed to have accepted the terms and conditions as
          amended by your continued use of vyatra Experiences and/or access to
          the site.
        </p>
        <h2 className="terms-h2" style={{ marginTop: "40px" }}>
          Material is indicative only
        </h2>
        <p className="About-p" style={{ fontWeight: "400" }}>
          2.1 We do not make any warranties or representations as to the
          accuracy, reliability or completeness of any statement, opinion or
          other information presented before, during, or after a vyatra
          Experience or on the site, including historical or scientific facts.
          Reliance upon any such opinion, advice, statement, or other
          information shall be at your risk.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          2.2 We shall not be liable for any loss, damage, liability or expense
          incurred or suffered which is claimed to have resulted from your use
          of such information, including without limitation, any fault, error,
          omission, interruption or delay with respect there
        </p>
        <h2 className="terms-h2" style={{ marginTop: "40px" }}>
          Alteration, Cancellation/Reschedule & Refund Policy
        </h2>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Your participation in a vyatra Experience will be confirmed upon
          payment of 100% of the fees at the point of booking.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          If you wish to cancel your booking,
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          We will refund your payment minus our third party cancellation charges
          provided that you have sent the cancellation 76 hours prior to the
          vyatra Experience;
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          We will not refund your payment if a cancellation is made within 76
          hours of the vyatra Experience
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          We will not refund payment if you are unable to attend a vyatra
          Experience for any reason including misunderstanding of timing or
          computer/internet/technology platform problems at your end, we will
          not refund your payment.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          We reserve the right to change a vyatra Experience in any manner we
          deem fit in response to unpredictable ground realities including but
          not limited to weather, internet bandwidth, availability of guides and
          performers and access restrictions to places. The changes may include
          the start and end times of the vyatra Experience or the places and
          people featured. We will not provide a refund for a vyatra Experience
          that is altered from its original description for any such reason.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          We reserve the right to cancel a vyatra Experience for any reason
          whatsoever, with refund policy as follows:
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          If the cancellation is due to circumstances beyond our control
          including but not limited to an act of God, riots, war, strikes, civil
          unrest, terrorism, governmental regulations, floods, earthquakes, fire
          and other natural disasters, then we will not refund your payment.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          If the cancellation is due to circumstances within our control, then
          we will refund your payment less any credit card/third party admin
          charges.
        </p>
        <h2 className="terms-h2" style={{ marginTop: "40px" }}>
          Restrictions on your use
        </h2>
        <p className="About-p" style={{ fontWeight: "400" }}>
          You agree you will not provide the online meeting details of a vyatra
          Experience to another person;
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          You agree you will not during a vyatra Experience:
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Make a video or audio recording of the vyatra Experience;
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Behave in a manner that does not conform to the decorum and
          sensibility of being in a public place;
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Reproduce third-party copyright materials; Post malicious, defamatory,
          abusive, off-topic, misleading or deceptive comments;
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Send unsolicited advice or engage in any activity which could be
          construed as spamming;
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Disclose identifying, personal, private or health-related information
          about any third party including other participants;
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Advertise, endorse, promote or sell any goods or services.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
        We reserve the right to expel you from the vyatra Experience with no refund if we find you doing any activities outlined in Clauses 4b (i)-(vi).
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          You agree you will not Translate, reverse engineer, decompile,
          disassemble, modify or create derivative works based on the site, the
          vyatra Experiences or any part of them;
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Circumvent any technology used by the site to protect content
          accessible via the site; Copy, store, edit, change, prepare any
          derivative work of or alter in any way any of the material appearing
          on the site; Rent, lease or sublicense the site or any part of it or
          use it on any commercial basis except under a separate written
          agreement with vyatra if so negotiated between the parties;
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Use the site in any way that violates these terms and conditions. We
          reserve the right to reject any person as a participant prior to
          beginning a vyatra Experience for any reason whatsoever.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          5.Communication with vyatra
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          By registering for a vyatra Experience, you consent to being contacted
          by us via email or text-messages for the following purposes and as per
          our Privacy Policy in Attachment A:
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          To provide links to the online vyatra Experience;
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          To remind you of your upcoming vyatra Experience;
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          To advise you about other relevant products and services offered by
          vyatra;
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          To invite you to take part in vyatra research, or research by
          affiliated organisations in relation to the vyatra Experiences (as
          further described below).
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Each e-mail or text message will offer you the option to opt-out of
          receiving future messages.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          You consent to the use of your personal information by us and/or any
          third parties we use in delivering the vyatra Experience and for the
          communication activities outlined in Clause 5a.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          We record all vyatra Experiences and may use photographs taken during
          the vyatra Experience for publications on the site and/or in
          print/social media promotional activities.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          ​If you choose to provide your social media details such as Facebook,
          Twitter and Instagram, you may be prompted at intervals to share your
          progression through the vyatra Experiences with your social media
          connections.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          User-generated content
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          By posting content during a vyatra Experience or on the site, you are
          granting vyatra a non-exclusive, worldwide, irrevocable, royalty free
          licence to reproduce, alter, publish and promote the content on the
          site and in connection with the promotion of the vyatra Experiences
          without limitation. You agree that vyatra is not required to credit or
          acknowledge you as the author of any such content.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          User-generated content represents the opinion of the individual user
          responsible for its posting only, and does not represent the views or
          opinions of vyatra, its directors or employees, nor constitutes a
          representation by the vyatra, its directors or employees.
          User-generated reviews and endorsements of products, including the
          vyatra Experiences, are not to be taken as reviews or endorsements by
          vyatra, nor as representations by vyatra.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          vyatra makes no warranties in regard to the suitability of any
          user-generated content for any particular audience. You acknowledge
          that users of the site may post content which is not suitable for
          children. vyatra Experiences are live and therefore uncontrollable.
          Similarly, the site is unmoderated. If you have concerns about another
          user’s behaviour, please contact us. If vyatra deletes or edits a
          comment, this does not imply endorsement of the remaining, unedited
          comments.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Indemnity You agree to indemnify vyatra and keep vyatra, its officers,
          directors, employees, servants, agents, licensors, licensees and
          suppliers, indemnified from and against all losses, expenses, damages
          and costs, including legal fees, resulting from any breach of these
          terms and conditions or any activity related to your registration
          (including negligent or wrongful conduct) by you or any other person
          using your registration.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          We and/or our representatives hereby assume no liability in contract,
          tort or otherwise on account of loss of life, personal injuries or
          property damages to the Guest arising out of any events, happenings or
          occurrence during the tour except where such loss of life, personal
          injuries or property was caused by the fault or negligence of Company
          and except to the extent that such liability is imposed by law. You
          agree to indemnify us from and against any and all actions, claims,
          losses, damages, liabilities and expenses (including attorney’s fees)
          arising out of your conduct during a vyatra Experience, including
          without limitation any claims alleging facts that if true would
          constitute a breach by you of these terms and conditions.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          In delivering a vyatra Experience, we will use all reasonable efforts
          to procure the services of high quality third parties such as
          providers of local guides and storytellers, operators of places of
          accommodation and places of interest, providers of food, refreshments
          and other facilities. These service providers are independent
          contractors and are not our agents, employees, or partners, and we
          shall not be held liable for any personal damage, loss of life or
          property or expenses incurred due to any act or omission on account of
          such third parties. The site may present links to third-party sites or
          third-party services not owned or operated by vyatra. We are not
          responsible for the availability of these third-party sites or
          services or their contents. Accessing a linked site from this site
          does not expressly or impliedly constitute any guarantee, undertaking
          or warranty on the part of vyatra as to the accuracy, completeness,
          copyright status or up to date nature of the information contained on
          the linked site. vyatra will not be liable to the reader or any third
          party for losses, costs, damages or other expenses incurred as a
          result of such access and the use of any information contained on a
          linked site.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          The provision of a link to a third-party site does not:
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Constitute express or implied authority to infringe copyright in any
          material contained on the linked site; and/or Imply any connection,
          sponsorship, endorsement or affiliation between the linked site and
          this site or vyatra.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Liability
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          The site (including any software contained in the site) and any
          upgrades or plug-ins and any licensed content is licensed to you “as
          is”. Any use of the site will be at your own risk. To the maximum
          extent permitted by law, vyatra disclaims all warranties, either
          express or implied, including but not limited to implied warranties of
          fitness for a particular purpose, title and non-infringement. vyatra
          makes no representations or guarantees that use of the site or the
          vyatra Experiences will be free from loss, damage, corruption, attack,
          viruses, interference, hacking or other security intrusion, and vyatra
          disclaims any liability thereto
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          You agree that we are not responsible or liable, directly or
          indirectly, for any damage or loss caused by or in connection with
          your use of or reliance on any content contained within the site and
          the vyatra Experiences.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          vyatra makes no guarantees, representations or warranties that use of,
          or content on, the site will be accurate, reliable, current,
          uninterrupted or without errors. Without prior notice vyatra may
          modify, suspend or discontinue the site and your use of it. Whenever
          vyatra elects to modify, suspend or discontinue the site it will not
          be liable to you or any third party.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          You acknowledge that your use of the site and the vyatra Experiences
          is at your own risk. vyatra is not liable for any loss or damage
          relating to such use.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          You understand that you may encounter offensive, indecent or other
          objectionable content when using the site. vyatra is not liable for
          any such content. Some of the content, products and services available
          to you through the site may include material that belongs to third
          parties. You acknowledge that vyatra assumes no responsibility for
          such content, products or services.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Termination
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          These terms and conditions shall continue to have full force and
          effect until terminated by vyatra.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          vyatra reserves the right to terminate this agreement and your access
          to the site at any time for any reason. The terms and conditions
          relating to intellectual property, your licence to vyatra, the
          indemnity granted by you, and all disclaimers and limitations of
          liability shall survive the termination of this agreement.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Intellectual Property
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          All information, text, material, visuals, sounds, graphics, software,
          design, data, video and film within a vyatra Experience or on the site
          (“material”) are Copyright © 2020 vyatra Pty Ltd unless expressly
          indicated otherwise.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          You must not modify, copy, reproduce, republish, frame, upload to a
          third party, post, transmit, distribute or in any way deal with the
          material except as expressly provided on the site, or expressly
          authorised in writing by vyatra.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          SCHEDULE A: Privacy Policy
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          This Privacy Policy explains how your personal information will be
          treated when you use the site or when you become a member or use any
          goods or services (including the vyatra Experiences) offered to you by
          or on behalf of vyatra. Personal Information is any information about
          you where your identity is apparent, or can reasonably be ascertained,
          from the information (“Personal Information”).
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          By providing your Personal Information to vyatra, through the site,
          over the phone, via email, or via an vyatra affiliate or distributor,
          you agree to the collection, use and disclosure of that information in
          accordance with this Privacy Policy. If you do not consent to these
          uses of your Personal Information, we cannot provide you with the
          vyatra Experiences
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          What this policy is about
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          This policy explains the key measures we have taken to implement the
          requirements of the Australian Privacy Act 1998, which implements the
          National Privacy Principles. It aims to answer the questions you might
          have about how we collect, use and disclose your Personal Information.
          If you have any further questions about vyatra privacy practices,
          please contact us at admin@vyuat.com.au or admin@vyuat.com.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          What information we collect from you
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          We hold information that you have provided to us about yourself, and
          information about your use of our services such as the vyatra
          Experiences. The information that we collect will depend on how you
          use the services offered by vyatra. Information that you might have
          provided to us includes:
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          information provided in registering for the vyatra Experiences – for
          example, your contact details and credit card details;
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          information provided as part of your use of the vyatra Experiences –
          for example, the frequency with which and the times at which you use
          the program information provided as part of your use of the vyatra
          site – for example, the email address you provide to participate in
          blogs, webinairs or forums, or to download the vyatra Experiences; and
          information relating to your health – for example, the existence and
          nature of a medical condition disclosed to us in completing
          questionnaires, in discussions on blogs or in responding to queries
          from our research partners.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          What we do with this information
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          We collect information about you to provide you with membership of the
          site and to download the vyatra Experiences, and to offer you other
          vyatra products and services. We may also use Personal Information for
          related purposes such as to communicate with you; to record your
          progress through the vyatra Experiences;
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          if you so choose, to link your vyatra Experiences account to your
          Facebook and Twitter accounts and to display updates about your
          progress through the vyatra Experiences on your Facebook and/or
          Twitter accounts to record information about our users goals, useage,
          preferences and behaviour in relation to the vyatra Experiences, as
          well as any feedback you share with us; o perform statistical analyses
          of user behaviour; maintaining our relationship with you, including
          responding to your questions; helping us to identify and inform you
          about other vyatra products or services that are likely to be of
          interest to you internal accounting and administration, including
          sharing information with our related bodies corporate for reporting
          purposes; protecting you and vyatra from fraud; and any other use for
          which we obtain your permission.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          We may share overall visitor trends and other generic information
          collected on this site with third parties but we do not pass on any
          personal details or personally identifiable information except for in
          accordance with this Privacy Policy.
        </p>
        <h2 className="terms-h2" style={{ marginTop: "40px" }}>
          How long we will you keep your Personal Information
        </h2>
        <p className="About-p" style={{ fontWeight: "400" }}>
          We will keep your Personal Information for as long as it is required
          to provide you with our services and to comply with legal
          requirements. If we no longer require your Personal Information for
          any purpose, including legal purposes, we will take reasonable steps
          to securely destroy or permanently de-identify your Personal
          Information.
        </p>
        <h2 className="terms-h2" style={{ marginTop: "40px" }}>
          Sharing your information with others
        </h2>
        <p className="About-p" style={{ fontWeight: "400" }}>
          vyatra recognises the trust you place in us when you give us your
          Personal Information. Other than disclosure to service providers
          (explained below) or as required by law (for example, disclosure to
          various Government departments or to courts), our policy is that we do
          not give your Personal Information to other organisations unless you
          have given us your consent to do so.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          We may share your Personal Information with certain third parties,
          such as the providers of the electronic systems we use to collect and
          store your Personal Information; banks and financial institutions you
          use to make payments to us; and other service providers we use to help
          us run aspects of our business efficiently. Occasionally vyatra might
          also use your Personal Information for other purposes or share your
          information with another organisation because we believe it is
          necessary to provide you with a service which you have requested; we
          believe it is necessary to protect the rights, property or personal
          safety of another Allevi8 users; we believe it is necessary to do so
          to prevent or help detect fraud or serious credit infringements – for
          example, we may share information with other, credit reporting
          agencies, law enforcement agencies and fraud prevention units; we
          believe it is necessary to protect the interests of vyatra – for
          example, disclosure to a court in the event of legal action to which
          vyatra is a party; or the assets and operations of the business are
          being transferred to another party as a going concern
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          When we share information with other organisations and service
          providers as set out above, we do so in accordance with this Privacy
          Policy. To the extent that these organisations and service providers
          gain access to your Personal Information, they are covered by strict
          privacy rules that prevent them from keeping this information or using
          this information for any other purpose.
        </p>

        <h2 className="terms-h2" style={{ marginTop: "40px" }}>
          Collection of information from the site
        </h2>
        <p className="About-p" style={{ fontWeight: "400" }}>
          The site is designed to give you useful information about vyatra and
          the vyatra Experiences. To assist us in doing this, we collect visitor
          information related to browser and operating systems. We also use
          cookies to ensure you can make online transactions securely. ‘Cookies’
          are alphanumeric identifiers that are stored by your web browser on
          your computer’s hard-drive that enable our system to recognise you
          when you visit the site. This helps vyatra to track basic visitor
          information so we can better tailor the site to our visitors’ needs.
          Most web browsers automatically accept cookies but you can disable
          this function by changing your browser settings if you so wish.
        </p>
        <h2 className="terms-h2" style={{ marginTop: "40px" }}>
          Accessing information we keep about you
        </h2>
        <p className="About-p" style={{ fontWeight: "400" }}>
          You can access the Personal Information we hold about you at any time.
          Simply contact us to make your request at admin@allevi8.net We will
          always endeavour to meet your request for access. However, in some
          circumstances we may decline a request for access. This includes the
          following circumstances:
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          we no longer hold or use the information; providing access would have
          an unreasonable impact on the privacy of other persons; the request is
          frivolous or vexatious; the information relates to existing or
          anticipated legal proceedings and would not normally be disclosed as
          part of those proceedings; providing access would be unlawful;
          providing access would be likely to prejudice the detection,
          prevention, investigation and prosecution of possible unlawful
          activity; and the information would reveal our commercially sensitive
          decision-making process. If we decline your request for access, we
          will give you reasons for our decision when we respond to your
          request.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          We reserve the right to charge you a reasonable fee for access to some
          types of information. These charges will be limited to the cost of
          recouping our expenses for providing you with information. For
          example, document retrieval, photocopy, labour and delivery to you. We
          will not charge you to make a request to access your information.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Changing or deleting the information To provide you with the best
          possible service, it is important that the information we hold about
          you is accurate. We will take all reasonable steps to ensure that your
          Personal Information is accurate, complete and up-to-date at the time
          of collecting, using or disclosing the information. If you believe
          that any information we hold about you is inaccurate, incomplete or
          out-of-date, you should notify us. We will do our best to correct it
          or delete it, unless we need to keep it for legal reasons.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Security
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          vyatra endeavours to take all reasonable steps to keep your Personal
          Information secure. We store this information on secure servers that
          are protected in access-controlled facilities. Once it is stored on
          our servers, only authorised users can access your Personal
          Information, and access is only for approved purposes. vyatra is not
          responsible for any third-party access to your Personal Information as
          a result of: interception while it is in transit over the internet;
          spyware or viruses on the device (such as a computer or phone) from
          which you access the site and vyatra Experiences, nor as a result of
          your failure to adequately protect your membership user name or
          password; nor for any losses, expenses, damages and costs, including
          legal fees, resulting from such access.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Providing information on other sites
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Allevi8 provides links to some other sites outside Allevi8. Sometimes
          other sites – such as the sites of our affiliates and distributors –
          link back to the Allevi8 site. These linked sites are not under our
          control, so we are not responsible for the conduct of companies linked
          to our site. Before you enter information on to those sites, you
          should look at their privacy policy and terms and conditions of use.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          What to do if you have a problem, question or complaint
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          If you have any further queries relating to our Privacy Policy, or you
          have a concern, please contact Allevi8 at admin@allevi8.net. If vyatra
          becomes aware of any ongoing concerns or problems with customer
          information, we will take these issues seriously and work to address
          these concerns.
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          Changes to this Privacy Policy
        </p>
        <p className="About-p" style={{ fontWeight: "400" }}>
          From time to time, our policies will be reviewed and may be revised.
          vyatra reserves the right to change this Privacy Policy at any time
          and notify you by posting an updated version of the Policy on our
          site. Before providing us with Personal Information, please check this
          Policy on the site for any changes.
        </p>
        <p
          className="About-p"
          style={{ fontWeight: "400", marginBottom: "30px" }}
        >
          This Privacy Policy was last updated in October 2020.
        </p>
      </Container>
      <Emailus />
    </>
  );
};

export default TermsAndCondition;
