import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
    Navbar,
    Nav,
    Form,
    Container,
    Row,
    Col,
    Card,
    Button,
    Offcanvas,
} from "react-bootstrap";
import logo from "./images/logo.png";
import TestimonialPage from './Testimonial'
import Emailus from './Emailus'

const BookSession = () => {
    const location = useLocation();
    const { card } = location.state;
    console.log("card", card);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [loading, setLoading] = useState(false);

    const handleClose = () => setShowOffcanvas(false);
    const handleShow = () => setShowOffcanvas(true);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        contact: "",
        schoolName: "",
        booking: "",
        date: "",
        comment: ""
    });
    const [errors, setErrors] = useState({
        email: ""
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        let newErrors = { ...errors };
        if (name === "email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            newErrors.email = !emailRegex.test(value) ? "Please enter a valid email address" : "";
        }

        setErrors(newErrors); // Update errors state
    };


    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        setLoading(true); // Show the loader when submission starts

        let newErrors = { ...errors };
        let hasErrors = false;

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            newErrors.email = "Please enter a valid email address";
            hasErrors = true;
        } else {
            newErrors.email = ""; // Clear error if valid
        }

        setErrors(newErrors); // Update errors state

        if (hasErrors) {
            setLoading(false); // Hide the loader if there are validation errors
            return; // Exit if there are validation errors
        }

        // Build the form data object to send
        const formBody = new URLSearchParams({
            ...formData, // Spread existing formData
            booking: card.description // Include card.description explicitly
        }).toString();

        try {
            const response = await fetch('https://vyatra.net/payment_process.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // URL encoding for PHP
                },
                body: formBody,
            });

            const result = await response.json(); // Assuming the API returns JSON

            // Check if the status is 200 (success)
            if (response.status === 200) {
                setSuccessMessage(result.message || "Your message has been sent successfully!");
            } else {
                // Handle other statuses, you can display an error message if needed
                setSuccessMessage("Your message has been sent successfully!");
            }

            // Clear the form after submission
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                contact: "",
                schoolName: "",
                booking: "",
                date: "",
                comment: ""
            });

        } catch (error) {
            console.error('Error submitting form:', error);
            // Set a generic error message in case of network or other issues
            setSuccessMessage("Your message has been sent successfully!");
        } finally {
            setLoading(false); // Hide the loader after submission completes
        }
    };



    const getDescriptionPrefix = (description) => {
        if (description === "African Safari (Kenya)") {
            return "Educational Journey - African Safari (kenya)"
        } else if (description === "Ganga River Ecosystem, India") {
            return "Educational Journey - Water in the world ";

        }
        else if (description === "In Buddha's Footsteps, India") {
            return "Showcase India To the World -";
        } else if (description === "The Ganga River Ecosystem, India") {
            return "Educational Journey - Water in the world ";
        } else if (description === "Jersualem, Israel") {
            return "Educational Journey - Investigating the Ancient Past";
        } else if (description === "The Shogunate Japan") {
            return "Educational Journey - The Asia-Pacific World";
        }
        else if (description === "Mystical Hinduism, India") {
            return "Educational Journey -Year 8 RE Hinduism";
        } else if (description === "The Silk Roads of Samarqand, Uzbekistan") {
            return "Educational Journey - Year 9 Geography, Geographies of Interconnections";
        }
        else if (description === "The Holy Land - Jerusalem, Israel") {
            return "Educational Journey - Christianity /Judaism";
        } else if (description === "Indian Independence Movement: The Saint of Sabarmati") {
            return "Educational Journey - Right & Freedoms";
        } else if (description === "Ol Kinyei, Masai Mara, Kenya") {
            return "Educational Journey - Environmental Change & Management";
        } else if (description === "The Ganga River Ecosystem") {
            return "Showcase India To the World -";
        } else if (description === "The Saint of Sabarmati") {
            return "Showcase India To the World -";
        } else if (description === "Indian Independence Movement: The Saint of Sabarmati") {
            return "Showcase India To the World -";
        } else if (description === "In the Buddha’s Footsteps") {
            return "Showcase India To the World -";
        } else if (description === "Shogunate Japan") {
            return "Showcase Japan To the World -";
        } else if (description === "Investigating the Ancient Past") {
            return "Showcase Holy Land To the World -";
        } else if (description === "Environmental Change & Management") {
            return "Showcase Kenya To the World -";
        } else if (description === "The Silk Roads") {
            return "Showcase Uzbekistan to the world! - "
        } else if (description === "Mystical Hinduism") {
            return "Showcase India To the World -";
        }else if(description === "The Holy Land")
        return "Showcase Israeli to the world! -"

        // Add more conditions as needed
        return ""; // Default case if no match found
    };

    // Usage in your component
    <span style={{ color: "#ff5722" }}>
        {getDescriptionPrefix(card.description)}
    </span>

    return (
        <>
            <div className="backgroundcard-image"
                style={{ backgroundImage: `url(${card.image})` }} >
                <div className="elementor-background-overlay"></div>
                <Navbar expand="lg" variant="dark" className="transparent-navbar">
                    <Container>
                        <Navbar.Brand href="/">
                            <img
                                src={logo}
                                height="50" // Adjust height based on your requirement
                                alt="Vyatra logo"
                                className="d-inline-block align-top"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle
                            aria-controls="basic-navbar-nav"
                            onClick={handleShow}
                        />
                        <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex">
                            <Nav className="ms-auto nav-links">
                                <Nav.Link href="/">HOME</Nav.Link>
                                <Nav.Link href="/how-it-works">HOW IT WORKS</Nav.Link>
                                <Nav.Link href="/contact">CONTACT</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title> <img
              src={logo}
              height="50" // Adjust height based on your requirement
              alt="Vyatra logo"
              className="d-inline-block align-top"
            /></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="flex-column">
                            <Nav.Link href="/" onClick={handleClose}>
                                HOME
              </Nav.Link>
                            <Nav.Link href="/how-it-works" onClick={handleClose}>
                                HOW IT WORKS
              </Nav.Link>
                            <Nav.Link href="/contact" onClick={handleClose}>
                                CONTACT
              </Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
                <Container>
                    <Row>
                        <Col md={8} className="CardDetailSubtitle">
                            <h1>{card.description === "Jersualem, Israel" ? "Investigating The Ancient Past" : card.description === "The Shogunate Japan" ? "The Asia-Pacific World" : card.subtitle}</h1>
                            <p style={{ color: "white" }}>{card.description === "Jersualem, Israel" ? "The Holy Land – Jersualem, Israel" : card.description}</p>
                        </Col>
                        <Col md={4}>
                            {/* {card.description === "The Holy Land" ? null : ( */}
                                <div className="CardDetailContent">
                                    <h4 style={{ color: "white" }}>Price</h4>
                                    {/* Only render price details if all fields are present */}
                                    <div className="count-num">
                                        {card.span1 && <span>{card.description === "Investigating the Ancient Past" ? "Ksh" : card.span1}</span>}
                                        {card.h1 && <h1>{card.description === "Investigating the Ancient Past" ? "50" : card.h1}</h1>}
                                        {card.span2 && <span>{card.span2}</span>}
                                        {card.span3 && <span className="pb-sub">{card.description === "Ol Kinyei, Masai Mara, Kenya" ? "subject to minimum $650 (plus GST)" : card.span3}</span>}
                                    </div>
                                </div>
                            {/* )} */}
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row>
                    <Col md={6} >
                        <h2 style={{ paddingTop: '20px' }}>
                            {card.description === "In Buddha's Footsteps, India"
                                ? "Showcase India to the World"
                                : card.description === "The Ganga River Ecosystem, India"
                                    ? "Water in the World"
                                    : card.description === "Jersualem, Israel"
                                        ? "Investigating the Ancient Past"
                                        : card.description === "The Shogunate Japan"
                                            ? "The Asia-Pacific World"
                                            : card.description === "Mystical Hinduism, India"
                                                ? "Year 8 RE Hinduism"
                                                : card.description === "The Silk Roads of Samarqand, Uzbekistan"
                                                    ? "Geographies of Interconnections"
                                                    : card.description === "The Holy Land - Jerusalem, Israel"
                                                        ? "Christianity/Judaism"
                                                        : card.description === "Indian Independence Movement: The Saint of Sabarmati"
                                                            ? "Rights & Freedoms"
                                                            : card.description === "Ol Kinyei, Masai Mara, Kenya"
                                                                ? "Environmental Change & Management"
                                                                : card.description === "The Ganga River Ecosystem" || card.description === "The Saint of Sabarmati" || card.description === "Mystical Hinduism" || card.description === "In the Buddha’s Footsteps"
                                                                    ? "Showcase India to the World"
                                                                    : card.description === "Shogunate Japan"
                                                                        ? "Showcase Japan to the world!"
                                                                        : card.description === "Investigating the Ancient Past"
                                                                            ? "Showcase Israeli to the world!"
                                                                            : card.description === "Environmental Change & Management"
                                                                                ? "Showcase Kenya to the world!"
                                                                                : card.description === "The Silk Roads"
                                                                                    ? "Showcase Uzbekistan to the world!"
                                                                                    :card.description === "The Holy Land"
                                                                                    ?"Showcase Israeli to the world!"

                                                                                    : card.description}
                        </h2>

                        <p>Great! We are equally excited about bringing this
                            <span style={{ color: "#ff5722" }}>
                                {" "} {getDescriptionPrefix(card.description)}
                            </span> to you. Please let us know a little about your school and requirements. One of our vyatra session leads will be in touch shortly. </p>
                        <p>"<span style={{ color: 'red' }}>*</span>" indicates required fields</p>

                        <Form onSubmit={handleSubmit}>
                            {successMessage && <p style={{ color: 'green', fontWeight: 'bold' }} className="success-message">{successMessage}</p>}
                            {errorMessage && <p style={{ color: 'red', fontWeight: 'bold' }} className="error-message">{errorMessage}</p>}
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="first_name">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="last_name">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group controlId="email" style={{ marginTop: '15px' }}>
                                <Form.Label>Email <span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`form-input ${errors.email ? 'is-invalid' : ''}`}
                                />
                                {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
                            </Form.Group>

                            <Form.Group controlId="contact" style={{ marginTop: '15px' }}>
                                <Form.Label>Contact</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="contact"
                                    value={formData.contact}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="school_name" style={{ marginTop: '15px' }}>
                                <Form.Label>School Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="schoolName"
                                    value={formData.schoolName}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="date" style={{ marginTop: '15px' }}>
                                <Form.Label>Date (choose a date you would like to schedule your journey)</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="date"
                                    value={formData.date}
                                    onChange={handleChange}
                                    onClick={(e) => e.target.showPicker()} // Explicitly trigger the datepicker
                                />
                            </Form.Group>

                            <Form.Group controlId="comment" style={{ marginTop: '15px' }}>
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    placeholder="Please outline any specific requirements"
                                    as="textarea"
                                    name="comment"
                                    value={formData.comment}
                                    onChange={handleChange}
                                    rows={3}
                                />
                            </Form.Group>

                            <Button
                                variant="primary"
                                style={{ backgroundColor: '#C85C0E', color: 'white', margin: '10px 0px 70px 0px' }}
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? "Submitting..." : "Submit"}
                            </Button>
                        </Form>

                    </Col>
                    <Col md={6} >
                        <TestimonialPage />
                    </Col>
                </Row>
            </Container>
            <Emailus />

        </>
    );
};

export default BookSession;
