import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Components/Home";
import HowItWorks from "./Components/HowItWorks";
import CardDetailPage from "./CardDetailPage";
import Contact from "./Contact";
import Footer from "./Footer";
import SmallCardDetailPage from "./SmallCardDetailPage";
import AboutUs from "./AboutUs";
import TermsAndCondition from "./TermsAndCondition";
import ReadMorePage from "./ReadMorePage";
import BookSession from "./BookSession";

const App = () => {
  return (
    <Router>
      {/* You can add a navigation bar here */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/about-us" element={<AboutUs />} />
        {/* <Route path="/card-detail/:id" element={<CardDetailPage />} /> */}
        <Route path="/:description" element={<CardDetailPage />} />

        <Route path="/detail/:id" element={<SmallCardDetailPage />} />
        <Route path="/for-australian-school" element={<ReadMorePage />} />
        <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
        <Route path="/book-session" element={<BookSession />} />
        

        {/* Add more routes as needed */}
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
