import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Correct hook for accessing location state
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Card,
  Offcanvas,
} from "react-bootstrap";
import logo from "./images/logo.png";
import "./Navbar.css";
import "./SmallCardDetailPage.css"; // Import styles for the detailed page
import African from "./images/africian.png"; // Import your image
import Ganga from "./images/dsd.png";
import kangaroo from "./images/australia_animal.png";
import Temple from "./images/photo.jpg";
import GangaRiver from "./images/gangariver.jpg";
import Sabarmati from "./images/sabarmati.jpg";
import Akshardham from "./images/akshardham.jpg";
import Buddhism from "./images/buddhism.webp";
import Japan from "./images/japan-the-world.png";
import Ancient from "./images/8-gl-03.jpg";
import Holyland from "./images/holyland.png";
import Environment from "./images/environment.jpg";
import Slik from "./images/slikroad.jpg";
import AustralianSchool from "./images/australian-school.png";
import IndianSchool from "./images/indian-school.png";
import JapanSchool from "./images/japan-school.png";
import IsraeliSchool from "./images/esraeli-school.png";
import KenyanSchool from "./images/kenyan-schools.png";
import { useNavigate } from "react-router-dom";

const SmallCardDetailPage = () => {
  const data = [
    {
      id: 1,
      title: "For Australian Schools",
      text: "Engage with classrooms across the world in real-time.",
      cards: [
        {
          id: 1,
          title: "Year 4 Geography",
          subtitle: "Year 4 Geography The Earth’s Climate",
          description: "African Safari (Kenya)",
          url: "African Safari (Kenya)",
          span1: "AU",
          h1: "$15",
          span2: "per student,",
          span3: "subject to minimum $650 (plus GST)",
          image: African,
        },
        {
          id: 2,
          title: "Year 7 Geography",
          subtitle: "Year 7 Geography Water in the World",
          description: "Ganga River Ecosystem, India",
          url: "Year 7 Geography Water in the World",
          span1: "AU",
          h1: "$15",
          span2: "per student,",
          span3: "subject to minimum $650 (plus GST)",
          image: Ganga,
        },
        {
          id: 3,
          title: "Year 8 Geography",
          subtitle: "Year 8 Geography Landforms and Landscapes",
          description:
            "The Bawaka Experience, Nhulunbuy, Northern Territory, Australia",
          span1: "AU",
          h1: "$1600",
          span2: " plus GST per session ",
          span3: "(unlimited number of students)",
          image: kangaroo,
        },
        {
          id: 4,
          title: "Year 9 Geography",
          subtitle: "Year 9 Geography Geographies of Interconnections",
          description: "The Silk Roads of Samarkand, Uzbekistan",
          span1: "AU",
          h1: "$15",
          span2: "per student,",
          span3: "subject to minimum $650 (plus GST)",
          image: Temple,
        },
        // {
        //   id: 5,
        //   title: "Year 7 Religious Education",
        //   subtitle: "Year 7 RE Buddhism",
        //   description: "In Buddha's Footsteps, India",
        //   span1: "INR",
        //   h1: "150",
        //   span2: "Per student",
        //   span3: "",
        //   image: kangaroo,
        // },
      ],
    },
    {
      id: 2,
      title: "For Indian Schools",
      text: "Learn about different cultures and enhance your global competence.",
      cards: [
        {
          id: 1,
          subtitle: "Year 7 Geography Water in the World",
          description: "The Ganga River Ecosystem",
          url: "ganga-river-ecosystem",
          span1: "INR",
          h1: "150",
          span2: "per student",
          span3: "",
          image: GangaRiver,
        },
        {
          id: 2,
          subtitle: "Year 10 History Rights & Freedoms",
          description: "The Saint of Sabarmati",
          url: "the-saint-of-sabarmati",
          span1: "INR",
          h1: "150",
          span2: "per student",
          span3: "",
          image: Sabarmati,
        },
        {
          id: 3,
          subtitle: "Year 8 Religious Education",
          description: "Mystical Hinduism",
          url: 'hinduism',
          span1: "INR",
          h1: "150",
          span2: "per student",
          span3: "",
          image: Akshardham,
        },
        {
          id: 4,
          subtitle: "Year 7 Religious Education",
          description: "In the Buddha’s Footsteps",
          url: 'buddhism',
          span1: "INR",
          h1: "150",
          span2: "per student",
          span3: "",
          image: Buddhism,
        },
      ],
    },
    {
      id: 3,
      title: "For Japanese Schools",
      text: "Practice new languages with native speakers in partner schools.",
      cards: [
        {
          id: 1,
          subtitle: "Year 8 History",
          description: "Shogunate Japan",
          url: 'shogunate-japan',
          span1: "JPY",
          h1: "1,900",
          span2: "per student",
          span3: "",
          image: Japan,
        },
      ],
    },
    {
      id: 4,
      title: "For Israeli Schools",
      text: "Practice new languages with native speakers in partner schools.",
      cards: [
        {
          id: 1,
          subtitle: "Year 7 History",
          description: "Investigating the Ancient Past",
          url: "the-holy-land",
          span1: "s",
          h1: "24",
          span2: "per student",
          span3: "",
          image: Ancient,
        },
        {
          id: 2,
          subtitle: "Year 9/10 Religious Education",
          description: "The Holy Land",
          url: 'judaism-christianity',
          span1: "S",
          h1: "24",
          span2: "per student",
          span3: "",
          image: Holyland,
        },
      ],
    },
    {
      id: 5,
      title: "For Kenyan Schools",
      text: "Practice new languages with native speakers in partner schools.",
      cards: [
        {
          id: 1,
          subtitle: "Year 10 Geography",
          description: "Environmental Change & Management",
          url: "masai-mara",
          span1: "Ksh",
          h1: "50",
          span2: "per student",
          span3: "",

          image: Environment,
        },
      ],
    },
    {
      id: 6,
      title: "For Uzbek Schools",
      text: "Practice new languages with native speakers in partner schools.",
      cards: [
        {
          id: 1,
          subtitle: "Year 8 Geography Geographies of Interconnections",
          description: "The Silk Roads",
          url: "silk-roads",
          span1: "som",
          h1: "2500",
          span2: "per student",
          span3: "",
          image: Slik,
        },
      ],
    },
  ];

  const location = useLocation();
  const card = location.state?.card; // Access the card data from the previous page
  console.log("card", card);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleCardClick = (card) => {
    // navigate(`/card-detail/${card.id}`, { state: { card } }); // Pass full card object
    const formattedDescription = card.url.toLowerCase().replace(/\s+/g, "-").replace(/[()]/g, ""); // Convert to lowercase, replace spaces with hyphens, remove special characters
    navigate(`/${formattedDescription}`, { state: { card } });
  };

  const handleBackToHome = () => {
    navigate('/'); // Navigates to the home page
  };
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);
  const matchingData = data.find((item) => item.title === card.title);
  return (
    <>
      <div
        className="backgroundSmallcard-image"
        style={{ backgroundImage: `url(${card.image})` }}
      >
        <div className="elementor-background-overlay"></div>
        <Navbar expand="lg" variant="dark" className="transparent-navbar">
          <Container>
            <Navbar.Brand href="/">
              <img
                src={logo}
                height="50"
                alt="Vyatra logo"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={handleShow}
            />
            <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex">
              <Nav className="ms-auto nav-links">
                <Nav.Link href="/">HOME</Nav.Link>
                <Nav.Link href="/how-it-works">HOW IT WORKS</Nav.Link>
                <Nav.Link href="/contact">CONTACT</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title> <img
              src={logo}
              height="50" // Adjust height based on your requirement
              alt="Vyatra logo"
              className="d-inline-block align-top"
            /></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-column">
              <Nav.Link href="/" onClick={handleClose}>
                HOME
              </Nav.Link>
              <Nav.Link href="/how-it-works" onClick={handleClose}>
                HOW IT WORKS
              </Nav.Link>
              <Nav.Link href="/contact" onClick={handleClose}>
                CONTACT
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
        <Container>
          <div className="SmallCard">
            <h1>{card.title}</h1>
          </div>

          <div className="BackToHome">
            <button className="back-btn" onClick={handleBackToHome}>Back To Home</button>
          </div>
        </Container>
      </div>

      {/* Render cards for the matching title */}
      {matchingData && (
        <Row
          className="mt-5"
          style={{
            backgroundColor: "#f5f5f5",
            padding: "10px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {matchingData.cards.map((card) => (
            <Col key={card.id} md={3} className="d-flex">
              <Card
                className="card-container"
                onClick={() => handleCardClick(card)}
                style={{ cursor: "pointer" }}
              >
                <Card.Img
                  variant="top"
                  src={card.image}
                  alt={card.title}
                  style={{ height: "320px", objectFit: "cover" }}
                />
                <Card.Body>
                  <Card.Title className="card-title">
                    {card.subtitle}
                  </Card.Title>
                  <Card.Text className="card-text">
                    <small>{card.description}</small>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

export default SmallCardDetailPage;
